import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as IGWhite } from '../assets/ig_icon_white.svg';
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";

import './NavigationMenu.css';


const NavigationMenu = (props: { closeMenu: () => void }) => {
  const location = useLocation();

  const scrollToTopIfNeeded = () => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // for smooth scrolling
      });
    }

    props.closeMenu();
  };

  return (
    <nav className="navigation-menu">
      <div className="navigation-menu-header">
        <div className="navigation-menu-spacer"></div>
        <TempoLogo className="navigation-menu-logo"></TempoLogo>
        <CloseIcon className="close-button" onClick={() => props.closeMenu()} />
      </div>
      <div className="navigation-menu-items">
        <Link to="/" onClick={scrollToTopIfNeeded}>
          <div className="navigation-menu-item">HOME</div>
        </Link>
        <Link to="/about-us" onClick={scrollToTopIfNeeded}>
          <div className="navigation-menu-item">ABOUT US</div>
        </Link>
        <Link to="/pricing" onClick={scrollToTopIfNeeded}>
          <div className="navigation-menu-item">PRICING</div>
        </Link>
        <Link to="/contact" onClick={scrollToTopIfNeeded}>
          <div className="navigation-menu-item">CONTACT</div>
        </Link>
        <div className="navigation-menu-item sign-in-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_in" }}>SIGN IN</div>
      </div>
      <div className="footer-content">
        <span>FIND YOUR <span className="footer-content-bold">TEMPO</span></span>
        <a href="https://www.instagram.com/tempopadelclub" target="_blank" rel="noopener noreferrer">
          <IGWhite className="instagram-icon" />
        </a>
      </div>
    </nav>
  );
};

export default NavigationMenu;