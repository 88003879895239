import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, ScrollRestoration } from 'react-router-dom';

import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import PricingPage from "./pages/PricingPage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";

import NavBar from "./components/NavBar";
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
    <Router>
      <NavBar></NavBar>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
