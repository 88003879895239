import "./JoinComponent.css";

const JoinNow = () => {
  return (
    <>
      <div className="joinnow">
        <h2>
          JOIN OUR
          <br></br>
          <b>COMMUNITY</b>
        </h2>
        <div className="join-container">
          <p>
            Unlock the full TEMPO experience with our app!
            Create an account to stay updated, reserve courts, sign up for memberships, shop the latest gear, join leagues, book clinics, or come to events.
          </p>
          <button className="join-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_up" }}>Join Now</button>
        </div>
      </div >
    </>
  );
};

export default JoinNow;
