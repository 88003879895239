import * as React from 'react';
import { ReactComponent as IgLogo } from "../assets/ig_icon_white.svg";
import { ReactComponent as XLogo } from "../assets/x_logo.svg";

interface IThankYouComponentProps {
  thankYouMessage: string;
}

const ThankYouComponent: React.FunctionComponent<IThankYouComponentProps> = (props) => {

  return <div className="confirmation-box">
    <div className="confirmation-text">
      {props.thankYouMessage}
    </div>
    <div className="follow-us">
      Follow us on social media for more updates!
    </div>
    <div className="logos-container">

      <a
        className="follow-us-icon"
        href="https://www.instagram.com/tempopadelclub"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: "3vw" }}
      >
        <IgLogo />
      </a>
      <a
        className="follow-us-icon"
        href="https://www.x.com/tempopadelclub"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: "3vw" }}
      >
        <XLogo />
      </a>
    </div>
  </div>;
};

export default ThankYouComponent;
