import React, { useState } from "react";
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";
import { ReactComponent as BurgerButton } from "../assets/burger_button.svg"
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import NavigationMenu from "./NavigationMenu";
import { ReactComponent as IGWhite } from '../assets/ig_icon_white.svg';


const NavBar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToTopIfNeeded = () => {
    if (location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      {
        menuOpen
          ?
          <NavigationMenu closeMenu={() => { setMenuOpen(false) }}></NavigationMenu>
          :
          <header className="header">
            <nav className="navbar">
              <div className="left-menu">
              <a href="https://www.instagram.com/tempopadelclub" target="_blank" rel="noopener noreferrer">
                <IGWhite className="instagram-icon" style={{marginRight: "30px"}} />
              </a>
                <Link to="/" onClick={scrollToTopIfNeeded}>
                  Home
                </Link>
                <Link to="/about-us" onClick={scrollToTopIfNeeded}>
                  About Us
                </Link>
              </div>
              <div className="nav-logo-container">
                <TempoLogo className="nav-logo" onClick={scrollToTopIfNeeded}></TempoLogo>
              </div>
              <div className="right-menu">
                <Link to="/pricing" onClick={scrollToTopIfNeeded}>
                  Pricing
                </Link>
                <Link to="/contact" onClick={scrollToTopIfNeeded}>
                  Contact
                </Link>
                <div className="nav-sign-in-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_in" }}>SIGN IN</div>
                </div>
              <div className="right-menu"></div>
              <BurgerButton className="burger-button" onClick={() => setMenuOpen(!menuOpen)} />
            </nav>
          </header>
      }
    </>
  );
};

export default NavBar;
