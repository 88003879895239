import React from "react";
import { ReactComponent as TempoLogo } from "../assets/tempo_and_logo.svg";
import { ReactComponent as BurgerButton } from "../assets/burger_button.svg"
import { Link, useLocation } from "react-router-dom";
import "./PricingPage.css";
import InfoFooter from "../components/InfoFooter";
import Footer from "../components/FooterComponent";
import PricingCardComponent from "../components/PricingCardComponent";
import PricingTable from "../components/PricingTable";


const PricingPage = () => {
  return (
    <div className="pricing-container">
      <div className="pricing-title-container">
        <h1 className="pricing-title">
          PRICING
        </h1>
        <p className="pricing-subtitle">
          Unlock a world of premium wellness and exhilarating sports with our flexible membership plans tailored to fit your lifestyle.
        </p>
      </div>
      <div className="pricing-section-2">
        <h1>
          <span>MEMBERSHIP</span>
          <br></br>
          PLANS
        </h1>
        <div className="membership">
          <PricingCardComponent
            membershipName="TEMPO Premium"
            description="Ultimate balance of flexibility and value. With priority bookings, you’ll enjoy the freedom to play when it suits you, while unlocking savings and perks designed to elevate your game."
            darkMode={true}
            price="$49.99"
            ctaUrl="https://tempo.playbypoint.com/memberships/4272"
            benefits={
              ["Booking Priority (21 days in advance)",
                "40% off Padel and Pickleball bookings",
                "Pro Shop Discounts",
                "Exclusive Wellness Access"
              ]} />
        </div>
        <div className="membership">
          <PricingCardComponent
            membershipName="TEMPO Unlimited"
            description="Perfect for players who want to level up their padel game. Enjoy unlimited* play, priority reservations, and exclusive access to wellness and Pro Shop discounts."
            darkMode={true}
            price="$189.99"
            ctaUrl="https://tempo.playbypoint.com/memberships/4273"
            benefits={
              ["Booking Priority (21 days in advance)",
                "Unlimited* bookings",
                "Pro Shop Discounts",
                "Exclusive Wellness Access",
                "Max 6 concurrent bookings*"
              ]} />
        </div>
        <div className="membership">
          <PricingCardComponent
            membershipName="TEMPO Pickleball Unlimited"
            description="Your ticket to non-stop action on the courts! Unlimited bookings for your share of any 4-person games, and open play bookings."
            darkMode={true}
            price="$79.99"
            ctaUrl="https://tempo.playbypoint.com/memberships/4274"
            benefits={
              ["Booking Priority (21 days in advance)",
                "Unlimited** pickleball bookings",
                "Pro Shop Discounts",
                "Exclusive Wellness Access",
                "**Booking is per person for 4-player game."
              ]} />
        </div>
      </div>
      <div className="pricing-table-container">
        <h1>
          <span>PRICE </span>LIST
        </h1>
        <PricingTable items={[
          { serviceName: "Padel Court 90mins (per player)", nonMember: "$21", member: "40% off" },
          { serviceName: "Pickleball Court 60mins (per player)", nonMember: "$9", member: "40% off" },
          { serviceName: "Book in advance", nonMember: "7 days", member: "21 days" },
          { serviceName: "Free cancellation", nonMember: "24 hours", member: "12 hours" },
          { serviceName: "Pro-Shop discounts", nonMember: "No", member: "Yes" },
          { serviceName: "Open play pickleball", nonMember: "$15", member: "40% off" }
        ]} />
      </div>
      <div className="booknow">
        <div className="booknow-title">
          <span className="style2">JOIN </span>
          NOW
        </div>
        <p className="booknow-description">
          Unlock the full TEMPO experience with our app! Create an account to reserve courts, sign up for memberships, shop the latest gear, join leagues, book clinics, and enter tournaments!
        </p>
        <button className="pricing-booknow-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_up" }}>SIGN UP</button>
      </div>
      <InfoFooter />
      <Footer />
    </div>
  );
};

export default PricingPage;
