import React from 'react';
import "./Landing.css";
// import padelHug from "../assets/padel_hug.jpg"


const Landing = () => {
  return (
    <>
      <div className="main-title">
        <div className="headline">
          <h1 className="title">
            DISCOVER PREMIER
          </h1>
          <h1 className="subtitle">
            <b>PADEL & PICKLEBALL</b>
          </h1>
          <h1 className="subtitle2">
            IN HOUSTON
          </h1>
          <div className="full-height"></div>

          <button className="book-button" onClick={() => { window.location.href = "https://tempo.playbypoint.com/users/sign_up" }}>BOOK NOW</button>
        </div>
      </div>
      <div className="landing-section-2">
        <div className="landing-section-2-1">
          <h2 className="landing-section-2-title">
            ABOUT <b>TEMPO</b>
          </h2>
          <p>
            A padel & pickleball oasis where every match is an opportunity to connect, improve, and reach your maximum potential.
          </p>
        </div>
        <div className="landing-section-2-first-photo">
          <p>
            Play on world class courts, relax in our wellness space, and socialize in our lively bar area.
          </p>
        </div>
        <div className="landing-section-2-second-photo">
          <p>
            Join the fastest growing sports of padel and pickleball and reserve a court with your friends!
          </p>
        </div>
      </div>
      <div className="our-mission">
        <div className="our-mission-logo">
        </div>
        <h2 className="our-mission-title">
          OUR <b>MISSION</b>
          <p>
            To deliver an unmatched padel and pickleball club experience — where play, wellness, and connection unite to create a social, active lifestyle that enhances your physical, mental, and social wellbeing.
          </p>
        </h2>
      </div>
    </>
  )
};

export default Landing;
