import React from "react";
import ig_icon_white from "../assets/ig_icon_white.svg";
import tempo_logo from "../assets/tempo_logo.svg";
import "./FooterComponent.css";


const Footer = () => {
  return (
    <footer className="footer">
      <nav className="navfooter">
        <div className="nav-links">
          <a
            className="ig-icon"
            href="https://www.instagram.com/tempopadelclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="ig-img" src={ig_icon_white} alt="Tempo Instagram Account Link" />
          </a>
          <div className="footer-text">© Tempo 2024. All rights reserved.</div>
          <img className="tempo-logo-footer" src={tempo_logo} alt="The logo of Tempo features a bold, black uppercase letter 'T' stylized with a semi-circular arch forming the top of the 'T' and a straight vertical line as its stem, creating a simplistic and modern design that resembles a padel racket." />
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
