import React from "react";
import Landing from "./Landing";
import Footer from "../components/FooterComponent";
import InfoFooter from "../components/InfoFooter";
import JoinNow from "../components/JoinComponent";


function HomePage() {
  return (
    <>
      <Landing></Landing>
      <JoinNow></JoinNow>
      <InfoFooter></InfoFooter>
      <Footer></Footer>
    </>
  );
}

export default HomePage;
